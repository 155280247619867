<template>
  <div>
     <!-- BOC:[error] -->
  <AErrorV1 :api="api"></AErrorV1>
  <!-- EOC -->
    <v-select
      :label="$t('model.name.school')"
      key="id"
      class="white"
      :items="data"
      item-text="name"
      item-value="id"
      v-model="id"
      :loading="api.isLoading"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      if(resp[0]){
        this.id = resp[0].id;
      }
    };
    //EOC
  },
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.item = this.$_.find(this.data, { id: this.id });
      this.callbackSelect(this.item);
    },
  },
  methods: {
    fetch() {
      this.api.url = `${this.$api.servers.sso}/api/v1/en/moderator/migrate/data/institution`;
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>
