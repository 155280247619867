<template>
  <div>
     <!-- BOC:[error] -->
  <AErrorV1 :api="api"></AErrorV1>
  <!-- EOC -->
    <v-select
      :label="$t('model.name.student')"
      key="id"
      class="white"
      :items="data"
      item-text="name"
      item-value="id"
      v-model="id"
      :loading="api.isLoading"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError", "classroom"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.data.push({id:0,name:"All"})
      this.data.sort((a, b) => (a.id > b.id ? 1 : -1));
        this.id = resp[0].id;
    };
    //EOC
  },
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.item = this.$_.find(this.data, { id: this.id });
      this.callbackSelect(this.item);
    },
  },
  methods: {
    fetch() {
      this.api.url = `${this.$api.servers.classroom}/api/migrate/v1/student`;
      this.api.params = {classroomId:this.classroom.id}
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
  },
};
</script>
