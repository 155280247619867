<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>Migrate Classroom</v-card-title>
        <v-card-text>
          <template>
            <Institution
              :callbackSelect="selectInstitution"
            />
          </template>
          <template>
            <Classroom
            ref="classroom"
            :institution="institution"
            :callbackSelect="selectClassroom"
            />
          </template>
          <template>
            <Student
            ref="student"
            :classroom="classroom"
            :callbackSelect="selectStudent"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Institution from "@/components/Moderator/Migrate/Institution";
import Classroom from "@/components/Moderator/Migrate/Classroom";
import Student from "@/components/Moderator/Migrate/Student";
import delay from "delay";
import { mapState } from "vuex";
export default {
  props: ["data", "callbackSuccess"],
  components: {
    Institution,
    Classroom,
    Student
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      institutionId: null,
      classroomId: null,
      studentId: null,
    },
    fields: [],
    rules: {},
    institution:{},
    classroom:{}
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.sso}/api/v1/en/moderator/migrate/classroom`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
  async selectInstitution(item){
 
      this.institution = item
      this.form.institutionId = item.institutionId
      await delay(100)
        this.$refs["classroom"].fetch();
    },
   async selectClassroom(item){
      this.classroom = item
this.form.classroomId = item.id
await delay(100)
        this.$refs["student"].fetch();
    },
    selectStudent(item){
this.form.studentId = item.id
    },
    //BOC:[form]
    validate() {
      //this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>